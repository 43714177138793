import styled from "styled-components";

import { shadow } from "lib/palette";
import { zIndexes } from "lib/z-indexes";

export const StyledShadowBlock = styled.div<{ customShadowHeight?: string }>`
    width: 100%;
    height: auto;
    position: relative;

    &.withLeftShadow::after {
        content: "";
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 10px;
        height: ${({ customShadowHeight }) => customShadowHeight || "100%"};
        background: ${shadow.left};
        z-index: ${zIndexes.shadow};
    }

    &.withRightShadow::before {
        content: "";
        display: inline-block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 10px;
        height: ${({ customShadowHeight }) => customShadowHeight || "100%"};
        background: ${shadow.right};
        z-index: ${zIndexes.shadow};
    }
`;

export const StyledScrollableBlock = styled.div`
    width: 100%;
    height: auto;
    overflow-x: auto;
`;
